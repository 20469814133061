import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import Contact from './Contact/Contact';

import { PortfolioProvider } from '../context/context';

import { heroData, contactData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [contact, setContact] = useState({});
  useEffect(() => {
    setHero({ ...heroData });
    setContact({ ...contactData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, contact }}>
      <Hero />
      <Contact />
    </PortfolioProvider>
  );
}

export default App;
